import {dedupingMixin} from '@polymer/polymer/lib/utils/mixin.js';

const stateChange = function(superClass){
    return class extends superClass{
        constructor(){
            super();
            this._stateChangeFuncs = [];
        }

        beforeStateChange(fn, args, context){
            if(typeof(fn) !== 'function'){
                throw new Error("beforeStateChange passed a value which is not a function");
            }
            this._stateChangeFuncs.forEach((item) => {
                if(item.fn === fn){
                    console.warn("beforeStateChange was passed multiple copies of the same function.");
                }
            });
            args = args || [];
            this._stateChangeFuncs.push({fn, args, context});
        }

        beforeStateChangeUnsubscribe(fn){
            const prevLength = this._stateChangeFuncs.length;
            if(prevLength === 0){
                console.warn("beforeStateChangeUnsubscribe called when no subscribers are present");
            }
            this._stateChangeFuncs = this._stateChangeFuncs.filter((item) => item.fn === fn);
            if(prevLength === this._stateChangeFuncs.length){
                console.warn("function to unsubscribe not found | beforeStateChangeUnsubscribe");
            }
        }

        _awaitStateChangeRequests(){
            return Promise.all(this._stateChangeFuncs.map(item => item.fn.apply(item.context, item.args)));
        }
    }
}

export const beforeStateChange = dedupingMixin(stateChange);