/**
 * Enqueues a command (set of arguments) for a provided namespace. If the namespace has not been
 * claimed, it adds the arguments to a _q proprety and persists them for later when the namespace is claimed.
 * every argument after name (the function takes any number of arguments) will be passed to the claiming function
 * as the 3rd, 4th, 5th... n+2 arguments. The first and second arguments to the claiming funciton will be the 
 * resolver and rejecter functions from the promise returned.
 *   
 * @param {String} name the namespace for the message queue
 * @return {Promise} 
 */
function _enqueueCommand(name){
    var reject, resolve;//to store the promise callbacks in the outer scope
    var thePromise = new Promise((res, rej) => {
        resolve = res.bind(this);//pass out the resolver and rejecter callbacks
        reject = rej.bind(this);
    });
	window._aiCommandQueue = window._aiCommandQueue || {};//if not present, create the command queue
	window._aiCommandQueue[name] = window._aiCommandQueue[name] || function(){//if namespace not present, establish a function to enqueue messages
        window._aiCommandQueue[name]._q = window._aiCommandQueue[name]._q || [];
        var args = [].slice.call(arguments);
		window._aiCommandQueue[name]._q.push(args);//place args into the queue _q
    };

    window._aiCommandQueue[name].apply(null, [resolve, reject].concat([].slice.call(arguments, 1)));//call the fn at that namespace with the resolver and rejecter prepended as args
    return thePromise;
}

/**
 * Calls the provided fn with the argument sets on namespace name in FIFO order.
 * Replaces the default enqueueing function at name with the fn for future messages.
 * for given message arguments 0,1...n the function fn will be called with
 * (resolve, reject, 0, 1 ... n) where the first argument is the resolver for the promise
 * given to the enqueuer, the second is the rejecter, and n+2 is the index of the argument in the
 * original message.
 * 
 * @param {String} name the namespace for the message queue
 * @param {Function} fn a function to execute for each message
 * @param {Context} context the `this` context to execute fn with
 */
function _consumeQueue(name, fn, context){
    window._aiCommandQueue = window._aiCommandQueue || {};//create command queue if not present
    const theQ = window._aiCommandQueue;
    if(theQ[name] && theQ[name]._q && theQ[name]._q.length){//if we have enqueued items
        const next = theQ[name]._q.shift();//remove the next item in the queue
        fn.apply(context, next);//call the consuming function with that item
        setTimeout(_consumeQueue.bind(this, name, fn, context), 0);//schedule next consumption as microtask
    }else{
        //if there are no more items on the queue
        theQ[name] = fn.bind(context);//replace the enqueueing function with the consumption func
        theQ[name]._processed = true;//mark this event (might not need this?)
    }
}

export const commandQueueEnqueue = _enqueueCommand;
export const commandQueueConsume = _consumeQueue;