import { dedupingMixin } from "@polymer/polymer/lib/utils/mixin";
import { dispatchEvent } from "./eventsHelper.js";
/**
 * Mixin providing open/close functionality per the Automatit oposing binary props behaviour.
 * implementers should provide an _open() and _close() methods which perform the relevant work.
 * Manages an opened/closed attribute and dispatches events.
 * 
 * @param {Class} superclass class to be decorated
 */
const _openClose = function(superclass){
    return class extends superclass{
        static get properties(){
            return {
                opened : {
                    type : Boolean,
                    value : false,
                    reflectToAttribute : true,
                    observer : '_openedChanged'
                },
                closed : {
                    type : Boolean,
                    value : true,
                    reflectToAttribute : true,
                    observer : '_closedChanged'
                }
            };
        }
        ready(){
            super.ready();
            this.__readyFired = true;
        }
        __dispatchAndCall(event, fn, detail = {}){
            dispatchEvent(event, this, detail);
            if(typeof fn === 'function')
                fn.call(this);
        }
        _openedChanged(newVal, oldVal){
            if(!this.__readyFired){
                return;
            }
            if(newVal && this.closed === false){
                this.__dispatchAndCall('open', this._open);
            }else if(!newVal && this.closed === true){
                this.__dispatchAndCall('close', this._close);
            }else{
                this.closed = !newVal;
            }
            
        }
        _closedChanged(newVal, oldVal){
            if(!this.__readyFired){
                return;
            }
            if(newVal && this.opened === false){
                this.__dispatchAndCall('close', this._close);
            }else if(!newVal && this.opened === true){
                this.__dispatchAndCall('open', this._open);
            }else{
                this.opened = !newVal;
            }
            
        }
        open(){
            this.opened = true;
        }
        close(){
            this.closed = true;
        }
    };
}

export const reveals = dedupingMixin(_openClose);