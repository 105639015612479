import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import { reveals } from './util/revealsHelper.js';
/**
* `ai-submenu`
* Container for a single tier drop-down menu. 
* 
* Implements the reveals pattern from the reveals helper mixin
*
* @customElement
* @polymer
*/
class AiSubmenu extends reveals(PolymerElement){
	static get is(){ return 'ai-submenu'; }
	static get template() {
	return html`
			<style>
				*{
					box-sizing: border-box;
				}
				:host, div {
					--_phue:var(--ai-menu-item-primary-color-hue, var(--ai-primary-color-hue, 230));
					--_psaturation:var(--ai-menu-item-primary-color-saturation, var(--ai-primary-color-saturation, 15%));
					--_plightness:var(--ai-menu-item-primary-color-lightness, var(--ai-primary-color-lightness, 90%));
					--_pchue:var(--ai-menu-item-primary-color-contrast-hue, var(--ai-primary-color-contrast-hue, 0));
					--_pcsaturation:var(--ai-menu-item-primary-color-contrast-saturation, var(--ai-primary-color-contrast-saturation, 0%));
					--_pclightness:var(--ai-menu-item-primary-color-contrast-lightness, var(--ai-primary-color-contrast-lightness, 10%));

					margin: 0;
					display: block;
					line-height: 1.2;
					padding: 0;
					position: absolute;
					top:100%;
					left: 0;
					min-width: 100%;
					background:var(--ai-submenu-background, hsl(var(--_phue),var(--_psaturation),var(--_plightness)));
					color:var(--ai-submenu-color, hsl(var(--_pchue),var(--_pcsaturation),var(--_pclightness)));
					box-shadow: var(--ai-submenu-box-shadow, 1px 3px 1px -2px rgba(0,0,0,.15), 0px 3px 4px rgba(0,0,0,.1));
				}

				:host([closed]) div{
					display: none;
				}
				:host([opened]) div{
					display: block;
				}
				
			</style>
			<div role='list'>
				<slot></slot>
			</div>
			`;
	}
	static get properties() {
		return {
			version : {
				type : String,
				value : '1.0.0',
				readonly : true
			}
		};
	}
	constructor(){
		super();
		this.removeAttribute('unresolved');
	}

	ready(){
		super.ready();
	}
}

window.customElements.define(AiSubmenu.is, AiSubmenu);
