import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import {commandQueueConsume} from './util/commandQueue.js';
import ResizeObserver from './util/ResizeObserver.es.js';
/**
* `ai-resize-pubsub`
* 
* 
* @customElement
* @polymer
* @demo demo/index.html
*/
class AiResizePubsub extends PolymerElement {
    static get is(){ return 'ai-resize-pubsub'; }
    static get template() {
        return html`
            `;
    }

    static get properties() {
        return {
            version : {
                type : String,
                value : '1.0.0',
                readonly : true
            },
            resizeMonitor : {
                type : Boolean,
                value : true,
                reflectToAttribute : true
            },
            _commandQueueNamespace : {
                type : String,
                value : 'ai-resize-pubsub',
                readonly : true
            }
        };
    }
    
    constructor(){
        super();
        this._resizeObserver;
        this._subscribers = new WeakMap();
        this._bindToSelf();
        this.removeAttribute('unresolved');
    }

    _bindToSelf(){
        super._bindToSelf && super._bindToSelf();
        this._resizeObserved = this._resizeObserved.bind(this);
        this._subscribe = this._subscribe.bind(this);
        this._enqueued = [];
    }

    connectedCallback(){
        this._consumeQueue();
        this._fireInitEvent();
        
        this._resizeObserver = new ResizeObserver(this._resizeObserved);
        if(this._enqueued.length > 0){
            this._enqueued.map(({element, callback}) => this._subscribe(element, callback));
            this._enqueued = [];
        }
    }

    ready(){
        super.ready();
    }
    
    _fireInitEvent(){
        const evt = new CustomEvent('resizepubsubinit', {
            detail : {
                element : this
            },
            bubbles : true,
            composed : true
        });
        this.dispatchEvent(evt);
    }

    /**
     * Consumes the command queue, handling any previously queued items and setting up for future queue items
     */
    _consumeQueue(){
        commandQueueConsume(this._commandQueueNamespace, (resolve, reject, element, callback) => {
            //add queued items to the subscribers array
            this._subscribe(element, callback);
            //resolve the command queue promise (denotes this item has been taken off the queue)
            resolve(this);
        });
    }

    /**
     * Callback for the resizeObserver.
     * 
     * Iterates over the entries and calls all subscriptions associated with them.
     * 
     * @param {ResizeObserverEntry[]} entries Array of observed events
     * @param {ResizeObserver} observer reference to the observer calling this handler 
     */
    _resizeObserved(entries, observer){
        entries.forEach((entry) => {
            if(this._subscribers.get(entry.target)){
                this._subscribers.get(entry.target).forEach((subscriber) => {
                    this._notify(subscriber, entry);
                });
            }
        });
    }

    /**
     * Subscribes the requestor to notifications for changes observed on the element.
     * 
     * @param {DOMNode} element the element to observe
     * @param {function} callback the callable to invoke on observed change
     */
    _subscribe(element, callback){
        if(! this._resizeObserver){//if the resize observer hasn't been initialized yet
            this._enqueued.push({element, callback});//add this item to the internal queue
            return;
        }
        //get the previous subscriptions to this element, or empty array
        var notifications = this._subscribers.get(element) || [];
        if(notifications.length == 0){//if this element is not being observed
            this._resizeObserver.observe(element);//add it to the observer
        }
        notifications.push(callback);//add the callback to the subscriptions
        this._subscribers.set(element, notifications);//overwrite previous subscriptions with [old + this]
    }

    //notify a single subscriber
    _notify(sub, entry){
        const size = entry.contentRect;
        sub.call(null, size);
    }
}

window.customElements.define(AiResizePubsub.is, AiResizePubsub);
