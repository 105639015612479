import {AwaitTaskFactory} from './taskManager.js';
// import { MarkerClusterer } from './markerclusterer.js';
export class AiMap{
	constructor({element, locations = [], opts}){
		this.delayedResolution = null;//resolver for dependency (init map called)
		this.initialized = new Promise((resolve, reject) => { //promise to track the initialization
			this.delayedResolution = resolve;
		});
		//create an await/enqueue for calls to methods
		//this will hold all method calls in a queue until the initialize funtion is called
		//allowing immediate execution of all tasks afterwards
		this.taskManager = AwaitTaskFactory(this.initialized, this);
		
		this.taskManager(function(){
			this._map = new google.maps.Map(element,
				Object.assign({}, opts)
			);
			this._markers = [];
			if(locations.length > 0){
				Promise.all(locations.map((location, i) => { 
					let marker = this.addMarker(location);
					return marker;
				})).then((resolvedMarkers) => {
					if(opts.fitBoundsByMarkers){
						this.boundMarkers(null, opts.maxZoomOnLoad);
					}
				});
			}
			this._opts = opts;
		})();//immediately invoke constructor tasks so they're first in the queue

		this.wrapMethodsWithTaskManager();
	}

	//invoked when dependencies resolve
	initMap(){
		this.delayedResolution();
		return this.taskManager(() => "mapInit")();
	}

	wrapMethodsWithTaskManager(){
		this.addMarker = this.taskManager(this.addMarker);
		this.boundMarkers = this.taskManager(this.boundMarkers);
		this.removeMarker = this.taskManager(this.removeMarker);
		this.resize = this.taskManager(this.resize);
	}

	/**
	 * 
	 * @param {*} location 
	 */
	addMarker(location){
		return new Promise((resolve, reject) => {
			let markerData = Object.assign({},{
				position: new google.maps.LatLng(+location.lat, +location.lng),
				map : this._map,
				zIndex : this._markers.length + 1
			}, location);
			let marker = new google.maps.Marker(markerData);
			if(location.markerEvents){
				for(let eventName in location.markerEvents){
					marker.addListener(eventName, location.markerEvents[eventName].bind(location));
				}
			}
			
			// if(this._opts.clusterMarkers){
			// 	if(! this._markerClusterer)
			// 		this.createMarkerClusterer();
			// 	this._markerClusterer.addMarker(marker);
			// }
			this._markers.push(marker);
			resolve(marker);
		})
	}

	setMarkerOpts(marker, opts){
		marker.setOptions(opts);
	}

	// _debouncedMarkerClusterer(){
	// 	if(this._markerClustererTimeout){
	// 		clearTimeout(this._markerClusterTimeout);
	// 	}
	// 	this._markerClustererTimeout = setTimeout(() => {
	// 		this.createMarkerClusterer();
	// 	}, 100);
	// }
	
	removeMarker(marker){
		return new Promise((resolve, reject) => {
			for(let i = 0; i < this._markers.length; i++){
				let m = this._markers[i];
				if(m == marker){
					this._markers = Array.prototype.concat(
						this._markers.slice(0, i),
						this._markers.slice(i+1)
					);
				}
			};
			marker.setMap(null);
			resolve();
		});
	}

	// createMarkerClusterer(){
	// 	this._markerClusterer = new MarkerClusterer(this._map, this._markers, 
	// 		{
	// 			gridSize : this._opts.markerClusterDistance,
	// 			imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
	// }

	changeMarkerIcon(marker, icon){
		marker.setIcon(icon);
	}
	changeMarkerIconUrl(marker, iconUrl){
		let icon = marker.getIcon() || {};
		icon.url = iconUrl;
		marker.setIcon(icon);
	}
	
	shiftMarkerZIndex(marker, zIndex){
		let z = marker.getZIndex() || 0;
		marker.setZIndex(z + zIndex);
		
	}

	resize(){
		return new Promise((resolve, reject) => {
			google.maps.event.trigger(this._map, 'resize');
			resolve();
		});
	}

	panZoom(latLng, zoom){
		if(typeof latLng !== 'object')
			throw new Error("latLng must be an object: aiMap.js:panZoom")
		this._map.panTo(latLng);
		if(zoom)
			this._map.setZoom(zoom);
	}

	/**
	 * Bounds the map to the markers on the map, optionally filtering for a subset
	 * 
	 * @param [Number[]]	ids 			just the ids to include in the bounding call
	 * @param [Number] 		restrictZoom  	a maximum zoom value which the map should be restricted to
	 */
	boundMarkers(ids, restrictZoom){
		return new Promise((resolve, reject) => {
			let markers = this._markers;
			if(ids){
				markers = markers.filter((marker) => ids.includes(marker.id));
			}
			let bounds = new google.maps.LatLngBounds();
			markers.forEach((marker) => {
				bounds.extend(marker.getPosition());
			});
			if(restrictZoom){
				//don't let the fit bounds zoom the map more than a specified value
				let removeAfter = this._map.addListener('bounds_changed',() => {
					requestAnimationFrame(() => {
						//defer execution so the bounds change event fully resolves
						if(this._map.getZoom() > restrictZoom){
							this._map.setZoom(restrictZoom);
						}
						google.maps.event.removeListener(removeAfter);
					});
				});
			}
			this._map.fitBounds(bounds);
			resolve();
		});
	}
}


// debugTimers(){
// 	this._map.addListener('bounds_changed',
// 		() => { 
// 			console.time('bounds_changed');
// 			console.timeEnd('bounds_changed');
// 		}
// 	);
// 	this._map.addListener('projection_changed',
// 		() => { 
// 			console.time('projection_changed');
// 			console.timeEnd('projection_changed');
// 		}
// 	);
// 	this._map.addListener('resize',
// 		() => { 
// 			console.time('resize');
// 			console.timeEnd('resize');
// 		}
// 	);
// 	this._map.addListener('tilesloaded',
// 		() => { 
// 			console.time('tilesloaded');
// 			console.timeEnd('tilesloaded');
// 		}
// 	);
// 	this._map.addListener('idle',
// 		() => { 
// 			console.time('idle');
// 			console.timeEnd('idle');
// 		}
// 	);
// }