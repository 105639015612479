/**
 * Factory that returns a function which accepts a target to listen to 
 * and a callback to execute. The callback will be run once and then the event 
 * listener will be removed.
 * 
 * @param {String} type The name of the event
 * @param {Context} context The this context for the execution
 */
export function eventOnceFactory(type, context){
    return function(element, fn){
        context = context || this;
        const cb = (function(){
            fn.call(context);
            element.removeEventListener(type, cb);
        }).bind(context);
        element.addEventListener(type,cb);
    }
}

/**
 * Binds and event handler to execute once and then remove itself.
 * 
 * @param {HTMLElement} element The element to listen to
 * @param {String} type The name of the event
 * @param {Function} fn Callabale to execute on the event
 * @param {Context} context the this context of the event
 */
export function eventOnce(element, type, fn, context){
    eventOnceFactory(type, context)(element, fn);
}

/**
 * Dispatches a custom event.
 * 
 * @param {String} name the name of the event.
 * @param {DOMElement} target the element to emit against.
 * @param {Object} detail Optional event detail/information to include in the event.
 * @param {Boolean} bubbles Whether or not the event should bubble out of the component (defaults to true).
 */
export const dispatchEvent = function(name, target, detail = {}, bubbles = true){
    const params = (o => {
        if(bubbles){
            o.bubbles=true;
            o.composed = true;
        }
        return o;
    })({ detail });

    const evt = new CustomEvent(name, params);
    target.dispatchEvent(evt);
}

// export const