import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
/**
* `ai-menu-link`
* 
* 
* @customElement
* @polymer
*/
class AiMenuLink extends PolymerElement {
	static get is(){ return 'ai-menu-link'; }
	static get template() {
	return html`
			<style>
				*{
					box-sizing:border-box;
				}
				:host {
					display: block;
					transition:var(--ai-menu-link-transition, background-color .2s);
					color: var(--ai-menu-link-color, inherit);
					background:var(--ai-menu-link-background, transparent);
				}
				:host(:hover){
					color:var(--ai-menu-link-hover-color);
					background:var(--ai-menu-link-hover-background, linear-gradient(180deg, hsla(200, 0%, 10%, 0.1), hsla(200, 0%, 10%, 0.3)));
				}

				a{
					display:block;
					line-height:inherit;
					font-size:var(--ai-menu-link-font-size, 1em);
					font-weight:var(--ai-menu-link-font-weight, 400);
					color:inherit;
					padding:var(--ai-menu-link-padding, .5em 1.1em);
					width:var(--ai-menu-link-width, 100%);
					text-decoration:var(--ai-menu-link-text-decoration, none);
					white-space:var(--ai-menu-link-white-space, nowrap);
				}
				
				:host(:hover) a{
					text-decoration:var(--ai-menu-link-hover-text-decoration, none);
				}
				
				a slot{
					pointer-events:none;/* Hack to fix behavior in Safari (┛◉Д◉)┛彡┻━┻ */
				}
				
			</style>
			<a href="[[href]]" role='listitem'><slot></slot></a>
			`;
	}
	static get properties() {
		return {
			version : {
				type : String,
				value : '1.0.0',
				readonly : true
			},
			href: {
				type: String,
				value: '',
			}
		};
	}
	constructor(){
		super();
		this.removeAttribute('unresolved');
	}

	ready(){
		super.ready();
	}
}

window.customElements.define(AiMenuLink.is, AiMenuLink);
