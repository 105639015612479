import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import { FlattenedNodesObserver } from '@polymer/polymer/lib/utils/flattened-nodes-observer.js';

/**
* `ai-menu-item`
* 
* 
* @customElement
* @polymer
*/
class AiMenuItem extends PolymerElement {
	static get is(){ return 'ai-menu-item'; }
	static get template() {
	return html`
			<style>
				*{
					box-sizing: border-box;
				}
				:host {
					--_phue:var(--ai-menu-item-primary-color-hue, var(--ai-primary-color-hue, 230));
					--_psaturation:var(--ai-menu-item-primary-color-saturation, var(--ai-primary-color-saturation, 15%));
					--_plightness:var(--ai-menu-item-primary-color-lightness, var(--ai-primary-color-lightness, 90%));
					--_pchue:var(--ai-menu-item-primary-color-contrast-hue, var(--ai-primary-color-contrast-hue, 0));
					--_pcsaturation:var(--ai-menu-item-primary-color-contrast-saturation, var(--ai-primary-color-contrast-saturation, 0%));
					--_pclightness:var(--ai-menu-item-primary-color-contrast-lightness, var(--ai-primary-color-contrast-lightness, 10%));

					position:relative;
					display: block;
					margin: var(--ai-menu-item-margin, 0);
					line-height: var(--ai-menu-item-line-height, 1.2);
					list-style: none;
					
					padding: var(--ai-menu-item-padding, 0);
					color: var(--ai-menu-item-color, hsl(0,0%,10%));
					background:var(--ai-menu-item-background, transparent);
				}
				:host(:hover){
					background: var(--ai-menu-item-hover-background, hsl(var(--_phue),var(--_psaturation),var(--_plightness)));
					color:var(--ai-menu-item-hover-color,  hsl(var(--_pchue),var(--_pcsaturation),var(--_pclightness)));
				}
				
			</style>
			<div role='listitem' on-mouseenter="mouseEntered" on-mouseleave="mouseLeft">
				<slot></slot>
			</div>
		`;
	}
	static get properties() {
		return {
			version : {
				type : String,
				value : '1.0.0',
				readonly : true
			}
		};
	}
	constructor(){
		super();
		this.nodeDescendants = [];
		this.removeAttribute('unresolved');
	}

	ready(){
		super.ready();
	}

	connectedCallback(e){
		super.connectedCallback();
		this._flattenedNodesObserver = new FlattenedNodesObserver(this, (info) => {
			this._updateNodeFocusTracking();
		});
		this._updateNodeFocusTracking();
	}
	
	mouseEntered(evt){
		this.openItems();
	}
	mouseLeft(evt){
		this.closeItems();
	}

	openItems(){
		const children = this.shadowRoot.querySelector('slot').assignedNodes();
		if(children.length){
			Array.prototype.slice.call(children).forEach((child) => {
				child.opened = true;
			});
		}
	}
	closeItems(){
		const children = this.shadowRoot.querySelector('slot').assignedNodes();
		if(children.length){
			Array.prototype.slice.call(children).forEach((child) => {
				child.opened = false;
			});
		}
	}

	/**
	 * Returns true if a node should be capable of receiving focus
	 * @param {*} node 
	 */
	_isTrackableNode(node){
		return node.nodeType === node.ELEMENT_NODE 
		&& (node.tagName === 'A' 
			|| node.tagName === 'AI-MENU-LINK' 
			|| (node.getAttribute('tabindex') !== null 
				&& node.getAttribute('tabindex') !== -1));
	}
	/**
	 * loops over descendants and updates the focus tracking collection and binds events
	 */
	_updateNodeFocusTracking(){
		let nodes = this.querySelectorAll('*');//info.addedNodes;
		if(nodes.length > 0){
			nodes.forEach((node) => {
				this._trackNode(node);
			});
		}
	}
	/**
	 * Checks to see if the current node is already tracked in the node descendants array. If not, adds it and binds the focus/blur events if 
	 * it is a focusable element 
	 * @param {*} node 
	 */
	_trackNode(node){
		if(!this.nodeDescendants.includes(node))
			this.nodeDescendants.push(node);
		if(node.focusListenerInit)
			return;				
		if(this._isTrackableNode(node)){
			node.addEventListener('blur', (evt) => {
				requestAnimationFrame(() => {//delay for next element to recieve focus. Body gets focus immediately after blur
					const root = node.getRootNode ? node.getRootNode() : document;
					if(this.nodeDescendants.filter(function(i){return i == root.activeElement}).length === 0){
						this.closeItems();
					}
				});
			});
			node.addEventListener('focus', (evt) => {
				this.openItems();
			});
			//mark node tracked
			node.focusListenerInit = true;
		}
	}
}

window.customElements.define(AiMenuItem.is, AiMenuItem);
