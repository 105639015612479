export const throttle = (fn, delay, tailEndInvoke = false) => {
    let timer = 0;
    let timeout = null;
    return (...args) => {
        clearTimeout(timeout);
        if(Date.now() >= timer + delay){
            timer = Date.now();
            fn(...args);
        }else if(tailEndInvoke){
            timeout = setTimeout(() => fn(...args), delay);
        }
    };
}