/**
 * Returns a function that can create a globally unique id value for a 
 * given typeString
 */
function getRand(){
	return Math.floor(Math.random() * 999999) + 1;//don't allow 0
}
export let uniqueId = (function(context){
	let ids = {};
	//garbage appended so to increase likelihood of uniqueness
	return context.uniqueIdGenbkjlosiaoie =
		context.uniqueIdGenbkjlosiaoie || function(type){
			ids[type] = ids[type] || {};
			let rand = getRand();
			while(ids[type][rand] !== undefined){
				rand = getRand();
			}
			ids[type][rand] = 1;//track id
			return rand;
		};
})(window);