/**
 * takes one or more promises and returns a function that can be used to wrap functions to defer
 * executions made against their returned functions until the outer promises have resolved.
 * 
 * @example
 * let awaitGen = awaitTaskFactory(loadedPromise, this);
 * let whenLoaded = awaitGen(function(){
 * 		console.log('loaded');
 * });
 * whenLoaded();
 * 
 * @param {Promise | Promise[]} awaitPromise one or more promises to await the resolution of
 * @param {executionContext} context the this context for the execution of the functions
 * @return {function} a function that can curry functions to defer their execution
 */
export let AwaitTaskFactory = function(awaitPromise, context = null){
	let initialized = false;
	let queue = [];
	if(! (awaitPromise instanceof Array)){
		awaitPromise = [awaitPromise];//so we can Promise.all
	}
	Promise.all(awaitPromise).then(function(){
		//once all promises have resolved, process queue
		while(queue.length){
			let next = queue.shift();
			next();
		}
		initialized = true;//mark resolution
	});

	//return a function that can be used to wrap other functions with this queue and context
	return function(curried){
		return (...args) => {
			//this is the invocation of the returned/curried function
			if(! initialized){
				//if the initialization hasnt happened yet, add this call to the queue
				let resolver = null;
				queue.push(() => {resolver(curried.call(context, ...args))});
				return new Promise((resolve, reject) => {
					resolver = resolve;
				});
			}else{
				//invoke immediately
				return Promise.resolve(curried.call(context, ...args));
			}
		}
	};
};